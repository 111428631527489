module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<script id=\"hotjar-page-script\">let hotjar_id=parseInt(\"")
    ; __append(escapeFn( state.analytics.hotjar_id ))
    ; __append("\"),hotjar_version=parseInt(\"")
    ; __append(escapeFn( state.analytics.hotjar_version ))
    ; __append("\");!function(t,s,h,e){t.hj=t.hj||function(){(t.hj.q=t.hj.q||[]).push(arguments)},t._hjSettings={hjid:hotjar_id,hjsv:hotjar_version},h=s.getElementsByTagName(\"head\")[0],(e=s.createElement(\"script\")).async=1,e.src=\"https://static.hotjar.com/c/hotjar-\"+t._hjSettings.hjid+\".js?sv=\"+t._hjSettings.hjsv,h.appendChild(e)}(window,document)</script>")
  }
  return __output;

}